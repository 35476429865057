:root {
    /* --debug-border: 1px dashed red; */
}

@font-face {
    font-family: 'gilroy-light';
    src: url('./fonts/Gilroy-Light.eot');
    src: url('./fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gilroy-Light.woff') format('woff'),
    url('./fonts/Gilroy-Light.ttf')  format('truetype'),
    url('./fonts/Gilroy-Light.svg#svgFontName') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

body {
    background-color: black;
    margin: 0;
    overflow: hidden;
    touch-action: none;
}

canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
}

.spinner-div {
    border: var(--debug-border);
    position: absolute;
    z-index: 1;
    width: 96px;
    height: 96px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.spinner-img {
    height: 100%;
    display: block;
    visibility: hidden;
    margin: auto;
    object-fit: contain;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.main-div {
    border: var(--debug-border);
    position: absolute;
    z-index: 1;
    width: 600px;
    height: 600px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.logo-div {
    border: var(--debug-border);
    width: 100%;
    height: 25%;
}

.logo-img {
    height: 100%;
    display: block;
    margin: auto;
    object-fit: contain;
}

.text-div {
    border: var(--debug-border);
    width: 100%;
    height: 12%;
    font-family: 'gilroy-light';
    color: white;
    font-size: 30px;
    display: flex;
    padding-top: 12px;
    align-items: center;
    justify-content: center;
}

.otp-div {
    border: var(--debug-border);
    width: 100%;
    height: 12.5%;
    display: flex;
    justify-content: space-between;
}

.otp-input {
    background-color: black;
    width: 10.9375%;
    height: 100%;
    border: 1px solid white;
    caret-color: white;
    font-family: 'gilroy-light';
    text-align: center;
    font-size: 30px;
    color: white;
    outline: none;
}

.otp-text {
    border: var(--debug-border);
    width: 5%;
    height: 100%;
    font-family: 'gilroy-light';
    text-align: center;
    font-size: 30px;
    color: white;
}

.vrbutton {
    position: absolute;
    left: 0; 
    right: 0; 
    top: 0;
    bottom: 0;
    margin-left: auto; 
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: max-content;
    height: max-content;
    padding: 12px 12px;
    border: 1px solid #fff;
    background: rgba(0,0,0,0.7);
    color: #fff;
    font-family: 'gilroy-light';
    font-size: 30px;
    text-align: center;
    z-index: 2;
}

@media screen and (max-width: 1400px) {
    .spinner-div {
        width: 64px;
        height: 64px;
    }

    .main-div {
        width: 400px;
        height: 400px;
    }

    .text-div {
        font-size: 20px;
        padding-top: 8px;
    }

    .otp-input {
        font-size: 20px;
    }

    .otp-text {
        font-size: 20px;
    }

    .vrbutton {
        font-size: 20px;
    }
}